import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const hrObj = {
  sex: "성별",
  age: "나이",
  bmi: "체질량지수",
  waist: "허리둘레",
  sbp: "수축기혈압",
  dbp: "이완기혈압",
  otchl: "총 콜레스테롤",
  ohdl: "HDL-콜레스테롤",
  otg: "중성지방",
  oalt: "ALT",
  oast: "AST",
  or_gtp: "감마지티피",
  cre: "혈청크레아티닌",
  oglu0: "공복혈당",
  smoke_yn: "흡연",
  drink_yn: "음주",
  sleep: "수면시간",
  fdm_yn: "가족력",
  pdht_yn: "고혈압",
  pdlp_yn: "고지혈증",
};

export const correctionFactor = ["흡연", "음주", "수면", "체질량지수", "복부둘레"];

export const correctionComment = {
  흡연: `하루에 1갑(20개피) 이상의 흡연을 하는 경우 비흡연자에 비해 당뇨 발생 위험이 55% 증가됩니다. 또한 흡연은 당뇨병의 원인이 되는 인슐린 저항성을 증가시키는 효과가 있습니다. 또한 당뇨병과 함께 심뇌혈관질환의 위험인자가 됩니다.`,
  음주: `위험 음주(폭음)은 1회 평균 음주량이 남자는 소주 7잔 이상, 여자는 5잔 이상이면서 주 2회 이상 음주를 하는 경우를 말합니다. 위험 음주는 인슐린 저항성을 유발할 뿐만 아니라 췌장염을 일으켜 인슐린 분비를 감소시켜 당뇨병 발생의 위험을 높일 수 있습니다. `,
  수면: `적절한 수면 시간은 당뇨병 예방에 중요한 역할을 하고 있습니다. 세계보건기구에서 추천하는 수면 시간은 7시간으로 당뇨병 발생 위험이 가장 낮았습니다. 5시간 미만으로 잠을 잘 시 당뇨병 발생은 2배, 8시간 이상 잠을 잘 시 3배 이상 증가합니다.`,
  체질량지수: `비만은 당뇨병 발생의 위험 요인으로 BMI 25kg/m^2를 기준으로 몸무게가 1% 증가할 때마다 당뇨병 발생위험도는 5% 증가하고, BMI가 1kg/m^2 증가할 수록 50% 증가하게 됩니다.`,
  복부둘레: `복부 둘레가 남자는 90cm 이상, 여자는 85cm 이상일 때 복부미만으로 진단되게 됩니다. 복부 비만은 다양한 대사 질환과 연과되어 있는데 복부 둘레가 정상 범위보다 5cm 이상 늘어날 수록 당뇨병 발생의 위험은 2배 증가하게 됩니다. `,
};

export const solution = {
  흡연: (
    <>
      <div className="result-txt">
        <div className="exp-txt">
          <div className="exp-tit">
            <span className="svg-icon-lg mr-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")} />
            </span>
            <span>금연</span>
          </div>
          <p>
            흡연습관 자가진단 및 니코틴 의존도 자가진단을 통해 나의 흡연습관 및 의존도에 대해 평가를
            하고 이를 바탕으로 나에게 맞는 방법을 찾는것이 필요합니다.
          </p>
        </div>
        <div className="exp-elem">
          <p>
            <span>부산시의 각 보건소 금연클리닉</span>
            <br />
            금연상담, 금연보조제 제공 등을 제공하며 스스로 금연하기 어려울 때 도움을 받을 수
            있습니다.
          </p>
          <p>
            <span>부산금연지원센터 (051-242-9030)</span>
            <br />
            스스로의 의지만으로 금연성공이 어려운 중증∙고도 흡연자를 대상으로 4박 5일간 입원치료를
            통해 금연교육, 심리상담, 의학적 치료 등을 제공하는 금연캠프를 운영하고 있습니다.
          </p>
        </div>
      </div>
    </>
  ),
  음주: (
    <>
      <div className="result-txt">
        <div className="exp-txt">
          <div className="exp-tit">
            <span className="svg-icon-lg mr-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")} />
            </span>
            <span>절주</span>
          </div>
          <p>
            건강에 미치는 영향은 알코올 섭취량에 달려있으며, 세계보건기구에서는 하루에 남자는 40g
            이내, 여자는 20g 이내로 제안하고 있습니다.
            <br />
            (1일 제한 음주량 - 남자: 소주 5잔, 와인 3잔 / 여자 : 소주 2.5잔, 와인 1.5잔)
          </p>
        </div>
        <div className="exp-elem">
          <p>
            <span>부산중독관리통합지원센터 (051-246-7570)</span>
            <br />
            알코올의존도 자가진단을 통해 나의 알코올의존증에 대해 평가하여 보고, 알코올의존증의
            가능성이 높은 경우 절주를 위한 상담이 필요합니다.
            <br />
            알코올 중독에 대한 자가진단 및 온라인 상담을 운영하고 있습니다.
          </p>
        </div>
      </div>
    </>
  ),
  수면: (
    <>
      <div className="result-txt">
        <div className="exp-txt">
          <div className="exp-tit">
            <span className="svg-icon-lg mr-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")} />
            </span>
            <span>수면</span>
          </div>
          <p>
            충분한 수면 시간 뿐만 아니라 수면의 양상도 당뇨병의 발생과 많은 관계가 있습니다. 수면의
            양상은 "수면의 질 지수 (Pittsburgh Sleep Quality Index : PSQI)"을 통하여 평가하여 볼 수
            있습니다. 수면의 질이 떨어져있거나 수면 중 과도한 코골이 또는 수면무호흡이 있는 경우에는
            수면클리닉 방문을 통하여 교정이 필요합니다.
          </p>
        </div>
      </div>
    </>
  ),
  체질량지수: (
    <>
      <div className="result-txt">
        <div className="exp-txt">
          <div className="exp-tit">
            <span className="svg-icon-lg mr-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")} />
            </span>
            <span>체질량지수</span>
          </div>
          <p>
            당뇨병 예방을 위해 대한당뇨병학회에서는 체질량지수 23kg/m^2인 당뇨병전단계 성인에게
            의학영양요법과 운동요법을 통해 생활습관교정과 체중 감량을 권유하고 있습니다. 전체 체중의
            5-10% 감량하고 유지하는 것을 권장하고 있습니다.
            <br />
            <br />
            권장되는 운동 방법은 다음과 같습니다. 1. 유산소운동 : 일주일에 150분 이상, 중강도로
            일주일에 적어도 3일 이상 하며, 연속해서 2일이상 쉬지 않아야합니다. 2. 저항 운동 : 근력
            운동, 유연성 운동 및 균형감각 운동을 일주일에 2회 이상 해야합니다. 3. 앉아서 생활하는
            시간을 최소화하기 위해 장시간 앉아 있는 것을 피하고, 30분 간격으로 잠시 걷거나 간단히
            활동하는 것을 권유드립니다. [자세한 운동방법은 유투브 "당뇨병의정석"에서 확인해주세요]
            <br />
            <br />
            영양요법에 대해서는 "식이패턴분석 맞춤당뇨병예측"을 참고해주세요.
            <br />
          </p>
        </div>
      </div>
    </>
  ),
  복부둘레: (
    <>
      <div className="result-txt">
        <div className="exp-txt">
          <div className="exp-tit">
            <span className="svg-icon-lg mr-1">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")} />
            </span>
            <span>복부둘레</span>
          </div>
          <p>
            당뇨병 예방을 위해 대한당뇨병학회에서는 체질량지수 23kg/m^2인 당뇨병전단계 성인에게
            의학영양요법과 운동요법을 통해 생활습관교정과 체중 감량을 권유하고 있습니다. 전체 체중의
            5-10% 감량하고 유지하는 것을 권장하고 있습니다.
            <br />
            <br />
            권장되는 운동 방법은 다음과 같습니다. 1. 유산소운동 : 일주일에 150분 이상, 중강도로
            일주일에 적어도 3일 이상 하며, 연속해서 2일이상 쉬지 않아야합니다. 2. 저항 운동 : 근력
            운동, 유연성 운동 및 균형감각 운동을 일주일에 2회 이상 해야합니다. 3. 앉아서 생활하는
            시간을 최소화하기 위해 장시간 앉아 있는 것을 피하고, 30분 간격으로 잠시 걷거나 간단히
            활동하는 것을 권유드립니다. [자세한 운동방법은 유투브 "당뇨병의정석"에서 확인해주세요]
            <br />
            <br />
            영양요법에 대해서는 "식이패턴분석 맞춤당뇨병예측"을 참고해주세요.
            <br />
          </p>
        </div>
      </div>
    </>
  ),
};
